import React, { Fragment } from 'react'
import Breadcump from '../../components/Breadcump'
import { Helmet } from "react-helmet"
import { useLanguage } from "../../hooks/useLanguage"
import { Top, Container, Content, Wrapper } from "./styles"
import Banner from '../../assests/images/PIPELINE2019_DSC_7430-2_.jpg'
import { ExpositorsList } from "../../components/ExpositorsList"

const contacts = [
    {
        id: 1,
        name: 'Adriene Kfuri',
        phone: '(21) 2112-9079 | (21) 97517-5891',
        email: 'adriene.kfuri@ibp.org.br'
    },
    {
        id: 2,
        name: 'Nelson Silva',
        phone: '(21) 2112-9078 | (21) 99105-7564',
        email: 'nelson.silva@ibp.org.br'
    }
]

function Expositors() {
    const { language} = useLanguage();
    
    return(
        <Fragment>
            <Helmet>
                <title>{language === 'en_US' ? '2023 Exhibitors List' : 'Lista de Expositores' } | Rio Pipeline</title>
            </Helmet>
            <Container>
                <Top BgImage={Banner} alt="Imagen de dutos">
                    <h1>{language === 'en_US' ? '2023 Exhibitors List' : 'Lista de Expositores'}</h1>
                </Top>
                <Breadcump customPath={
                  language === 'en_US'
                    ? [{ link: 'exhibitors', name: 'Exhibitors' }]
                    : [{ link: 'expositores', name: 'Expositores' }]
                } />
                <Content>
                    { language === 'en_US' ? 
                        <h2> Exhibition hours: 12 to 20pm.</h2>
                    : 
                        <h2>Horário de funcionamento da exposição: 12 às 20h.</h2>
                    }
                    { language === 'en_US' ? 
                        <h2>Check out the companies that have already secured their space in our next face-to-face edition.</h2>
                    : 
                        <h2>Confira as marcas que já garantiram o seu espaço na nossa próxima edição presencial.</h2>
                    }

                    {/*<div className="activate-your-brand">
                        <p>
                            Quer ativar a sua marca e fazer negócios no 
                            evento que reunirá novamente os principais profissionais e 
                            especialistas da comunidade internacional de dutos?
                        </p>
                        <span>Entre em contato com:</span>
                        <div className="activate-your-brand__content">
                            {contacts.map(contact => (
                                <div className="activate-your-brand__contact" key={contact.id}>
                                    <p>{contact.name}</p>
                                    <Wrapper>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                            <path d="M22.863,17.454l-1.786-1.786a2.858,2.858,0,0,0-4.008-.017,1.747,1.747,0,0,1-2.215.217,12.2,12.2,0,0,1-3.361-3.355,1.77,1.77,0,0,1,.231-2.223,2.858,2.858,0,0,0-.025-4L9.912,4.507a2.852,2.852,0,0,0-4.027,0l-.514.515C2.692,7.7,2.893,13.332,8.466,18.9c3.361,3.361,6.743,4.769,9.486,4.769A6.088,6.088,0,0,0,22.35,22l.515-.515A2.852,2.852,0,0,0,22.863,17.454Zm-1.148,2.883-.515.515c-2.111,2.111-6.769,1.725-11.588-3.1S4.407,8.274,6.517,6.163l.511-.514a1.227,1.227,0,0,1,1.731,0l1.786,1.786a1.227,1.227,0,0,1,.017,1.713,3.394,3.394,0,0,0-.431,4.253,13.808,13.808,0,0,0,3.826,3.82A3.392,3.392,0,0,0,18.2,16.8a1.227,1.227,0,0,1,1.719.011L21.708,18.6a1.226,1.226,0,0,1,.007,1.739Z" transform="translate(-3.698 -3.675)" fill="#009FE3"/>
                                        </svg>
                                        <span>{contact.phone}</span>
                                    </Wrapper>
                                    <Wrapper>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                            <g transform="translate(-618 -589)">
                                                <g transform="translate(618 589)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                                                    <rect width="20" height="20" stroke="none"/>
                                                    <rect x="0.5" y="0.5" width="19" height="19" fill="none"/>
                                                </g>
                                                <g transform="translate(616.998 587.799)">
                                                    <path d="M17.3,4H4.7A2.7,2.7,0,0,0,2,6.7v9a2.7,2.7,0,0,0,2.7,2.7H17.3A2.7,2.7,0,0,0,20,15.7v-9A2.7,2.7,0,0,0,17.3,4Zm-.6,1.8L11,10.076,5.3,5.8Zm.6,10.8H4.7a.9.9,0,0,1-.9-.9V6.926l6.661,5a.9.9,0,0,0,1.08,0l6.661-5V15.7A.9.9,0,0,1,17.3,16.6Z" fill="#009FE3"/>
                                                </g>
                                            </g>
                                        </svg>
                                        <span className="email">{contact.email}</span>
                                    </Wrapper>
                                </div>
                            ))}
                        </div>
                    </div>*/}

                    <ExpositorsList />
                </Content>
            </Container>
          </Fragment>
    )
}

export default Expositors;