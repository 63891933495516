import { Container, CTAButton } from "./style"
import { useLanguage } from "../../hooks/useLanguage"

import Banner from '../../assests/v1/featured-expositors.png'

export function FeaturedExpositors() {
    const { language } = useLanguage()

    return(
        <Container>
            <div className="container-content">
                <h2>{language === 'en_US'? 'Rio Pipeline exhibitors' : 'Expositores Rio Pipeline'}</h2>
                {/* <strong>{language === 'en_US'? 'Exhibition hours: 12 to 20pm.​' : 'Horário de funcionamento da exposição: 12 às 20h.'}</strong> */}
                <br></br>
                {language === "en_US" ? (
                    <p>Check out the exhibitors who have already confirmed their presence at the next edition of Rio Pipeline.</p>
                ) : (
                    <p>
                        Confira os expositores que já confirmaram presença na próxima edição da Rio Pipeline.
                    </p>
                )}
                <CTAButton href={language == 'en_US' ? '/exhibitors' : '/expositores'}>
                {language === 'en_US'? 'EXHIBITORS' : 'EXPOSITORES'}
                </CTAButton>
            </div>
            <img src={Banner} className="container-banner" />
        </Container>
    )
}

