import React from "react";
// import { Link } from 'react-router-dom'

import BannerForm from "../../components/BannerForm";
import SlideShow from "../../components/Slides";
import Player from "../../components/Player";
import GridSponsors from "../../components/GridSponsors";
import BannerParallax from "../../components/BannerParallax";
import EventInfo from "../../components/EventInfo";
import { useLanguage } from "../../hooks/useLanguage";
import { Helmet } from "react-helmet";
import { FeaturedExpositors } from '../../components/FeaturedExpositors';
import { FeaturedCity } from "../../components/FeaturedCity";

const Home = () => {
  const { language } = useLanguage();

  return (
    <>
      <Helmet>
        <title>Rio Pipeline</title>
        <link rel="canonical" href="/" />
      </Helmet>
      <h1 style={{ position: "absolute", display: "none" }}>
        Rio Pipeline - conference {"&"} exhibition
      </h1>
      <SlideShow />
      <EventInfo id="next-section" papers={false}/>
      <Player />
      <BannerParallax>
        <h2 className="content-text">
          {language === "en_US" ? "Conference" : "Congresso"}
        </h2>
        <p className="content-text">
          {language === "en_US"
            ? <span>Get ready for an unmissable edition of Rio Pipeline! We've expanded our agenda to include logistics, bringing strategic debates on transport and distribution to further strengthen the connections between supply chains and the pipeline sector. And there's more: to make the event even more accessible and global, in addition to the traditional simultaneous translation in English, we will also have Spanish translation in the main plenary and in the special sessions of the congress. Don't miss out!</span>
            : <span>Prepare-se para uma edição imperdível da Rio Pipeline! Ampliamos nosso temário para incluir a área de logística, trazendo à tona debates estratégicos sobre transporte e distribuição. Nosso objetivo? Fortalecer ainda mais as conexões entre as cadeias de suprimentos e o setor de dutos. E tem mais: para tornar o evento ainda mais acessível e global, além da tradicional tradução simultânea em inglês, teremos também tradução para o espanhol na plenária principal e nas sessões especiais do congresso. Não fique de fora!</span>}
        </p>
        <a href={language === 'en_US' ? "/call-for-papers" : "/call-for-papers"}>
          <button className='btn--outline'>
            {language === 'en_US'
              ? 'Check out the syllabus'
              : 'Confira o Temário'}
          </button>
        </a>
      </BannerParallax>
      <FeaturedExpositors />
      {/* @todo: só vai estar disponível em 2025 */}
      {/* <FeaturedCity /> */}
      { <GridSponsors />}
      <BannerForm />
      {/* <BannerNew /> */}
    </>
  );
};

export default Home;
